import React from 'react';
import styled from 'styled-components';
import { Link, Wrap, WrapItem, Tab, TabPanel, Select, Menu, MenuButton, MenuList, MenuItem, Button, useDisclosure } from '@chakra-ui/react'
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';


const ContactUs = (props) => {

	return ( 
    <div style={{ color: 'black', }}>
      <h1>Contact Us!</h1>
      <Wrap spacing='30px' justify='center'>
        <WrapItem>
          <Link href="tel:+19253051619">
            <Button leftIcon={<PhoneIcon />} colorScheme='teal' variant='solid' >James: (925)-305-1619 </Button>
          </Link>          
        </WrapItem>

        <WrapItem>
          <Link href="tel:+17079091177">
            <Button leftIcon={<PhoneIcon />} colorScheme='blue' variant='solid' >Andros: (707)-909-1177</Button>
          </Link>        
        </WrapItem>

        <WrapItem>
          <Link  href="mailto:walnutcreekflooringandtile@gmail.com">
            <Button leftIcon={<EmailIcon />} colorScheme='orange' variant='solid' >walnutcreekflooringandtile@gmail.com</Button>
          </Link>        
        </WrapItem>
      </Wrap>
          {/* <div style={{ color: 'black', marginLeft: "5px", display: 'flex', alignItems: 'center'}}>

            
            {" "}
            <Link href="tel:+19253051619">
              <Button leftIcon={<PhoneIcon />} colorScheme='teal' variant='solid' >James: (925)-305-1619 </Button>
            </Link>
            {" "}
            <Link href="tel:+17079091177">
              <Button leftIcon={<PhoneIcon />} colorScheme='blue' variant='solid' >Andros: (707)-909-1177</Button>
            </Link>
            {" "}
            <Link  href="mailto:walnutcreekflooringandtile@gmail.com">
              <Button leftIcon={<EmailIcon />} colorScheme='orange' variant='solid' >walnutcreekflooringandtile@gmail.com</Button>
            </Link>
            {" "}
          </div>   */}
    </div>  
	);
}
export default ContactUs;