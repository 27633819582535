import React from 'react';
import styled from 'styled-components';
import {  ChakraProvider,  Box,  Text, VStack,  Code,  Grid,  theme, GridItem, Tabs, TabList, Tab, TabPanels, TabPanel, Flex, Hide, SimpleGrid,} from '@chakra-ui/react';
import Carousel from './common/Carousel';
import { useEffect } from 'react';

const Title = styled.h2`
	color: #000;
	font-size: 28px;
	font-weight: 700;
	margin: 4px, 0;
  text-transform: capitalize;
`;

const Details = styled.p`
	color: #7a7a7a;
	font-size: 1em;
	text-align: left;
  white-space: pre-line;
  display: flex;
  align-items: center;
`;

const Flooring = (props) => {
  const [subTab, setSubTab] = React.useState(0);

  const importAll = require =>
  require.keys().reduce((acc, next) => {
    acc[next.replace("./", "")] = require(next);
    return acc;
  }, {});

  const tabIds = [
    {tab: "laminate", images: importAll(require.context(`../public/images/laminate`, false, /\.(png|jpe?g|svg)$/)), 
    text: "Laminate flooring is a type of flooring that consists of several layers of material fused together using heat and pressure. \n\n The top layer is a high-resolution photograph or pattern that's designed to look like a particular type of wood, tile, or stone. On top of the photograph is a clear protective layer that's designed to resist scratches, stains, and fading. The core layer of laminate flooring is typically made of high-density fiberboard or particleboard, which gives the flooring its stability and durability. The bottom layer is a melamine backing that provides additional support and moisture resistance. \n \n Laminate flooring is popular because it's relatively affordable compared to other types of flooring, and it's easy to install. It's also a good choice for high-traffic areas and households with pets and kids because it's highly durable and resistant to scratches and stains. Additionally, laminate flooring is available in a wide variety of styles, colors, and finishes, so it's easy to find a look that complements your home's decor." },
    
    {tab: "lvp", title: "Luxury Vinyl Plank", images: importAll(require.context(`../public/images/lvp`, false, /\.(png|jpe?g|svg)$/)),
      text: 'Luxury vinyl plank (LVP) is made from multiple layers of vinyl that are compressed together to create a durable and long-lasting flooring option. The top layer of LVP is designed to mimic the look of hardwood, with realistic textures and grain patterns, covered by a clear durable finish referred to as a "wear layer". The core of the flooring Is either a wood plastic composite (WPC) or stone plastic composite (SPC) sandwiched between two layers of vinyl.  The result is a thin, durable flooring that looks and feels like real wood. A foam or cork underlayment is often attached to give the flooring some cushion. \n\nOne of the benefits of LVP is that it is highly resistant to moisture and water, making it a great choice for areas of the home that are prone to spills or high humidity, such as bathrooms, kitchens, or basements. Additionally, LVP is typically very easy to install, with options for click-together or glue-down installations. \n\nLVP is available in a wide range of styles and colors, from light and airy to dark and dramatic, allowing homeowners to achieve a variety of different looks and aesthetics in their home. With its combination of durability, versatility, and style, luxury vinyl plank has become a popular choice for homeowners looking for a cost-effective and low-maintenance alternative to traditional hardwood flooring.'
    },

    {tab: "hardwood", images: importAll(require.context(`../public/images/hardwood`, false, /\.(png|jpe?g|svg)$/)),
      text: 'Hardwood flooring is a type of flooring material that is made from real solid wood. Strips are milled from a single piece of wood, typically from hardwood trees such as oak, maple, or walnut and are typically nailed or glued to the subfloor. \n\nThere are several different types of hardwood flooring available on todays market, including solid hardwood, engineered hardwood, and reclaimed hardwood. Solid hardwood flooring is the most traditional type, and is made from a solid piece of wood. Engineered hardwood flooring, has a thinner top layer of real wood glued to several layers of hardwood. The resulting flooring has increased stability but will not be able to be refinished as many times as solid hardwood. Reclaimed hardwood flooring is made from wood that has been salvaged from old buildings or structures, and can provide a unique, rustic look to a home. \n\nHardwood flooring is known for its durability, longevity, and natural beauty. It can be sanded and refinished multiple times throughout its lifespan, allowing it to be restored to its original appearance. Additionally, hardwood floors are relatively easy to clean and maintain, and can be a great investment for homeowners as they can increase the value of a home. '
    },
    {tab: "tile", images: importAll(require.context(`../public/images/tile`, false, /\.(png|jpe?g|svg)$/)), 
      text: "Ceramic flooring is made from fired clay or other natural materials. The clay is shaped into tiles and then fired in a kiln to create a hard, durable surface. Ceramic tiles can be glazed or unglazed, and are available in a wide range of colors, patterns, and textures, making them a versatile option for a variety of different interior design styles.\n\nOne of the benefits of ceramic tile flooring is its durability and resistance to water and moisture, making it a popular choice for areas of the home that are prone to spills or high humidity, such as bathrooms and kitchens. Additionally, ceramic tiles are relatively easy to clean and maintain, and can last for many years with proper care.\n\nCeramic tiles are available in a range of sizes, from small mosaic tiles to large format tiles, and can be installed in a variety of different patterns and layouts. \n\nWhile ceramic tile flooring is generally a more expensive option than other types of flooring, it's high durability and longevity actually make it one of the cheapest floorings after calculating total life cycle costs. Additionally, with its wide range of colors, patterns, and textures, ceramic tile flooring can be used to create a unique and personalized look in any room."
    },
    {tab: "carpet", images: importAll(require.context(`../public/images/carpet`, false, /\.(png|jpe?g|svg)$/)), 
      text: "Carpet is made from fibers, either synthetic or natural, that are woven or tufted into a backing material. Carpet is available in a wide range of styles, colors, textures, and patterns, making it a versatile option for a variety of different interior design styles. \n\nOne of the benefits of carpet flooring is its softness and comfort underfoot, which can make it a great choice for bedrooms, living rooms, and other areas where people spend a lot of time. Additionally, carpet can help to absorb sound and reduce noise levels, making it a popular choice for apartment buildings and other shared spaces. \n\nOne of the potential downsides of carpet flooring is its susceptibility to stains and spills, particularly in lighter colored carpets. However, many modern carpets are designed to be stain-resistant and easy to clean. \n\nOverall, carpet flooring can be a comfortable, cozy, and affordable option for homeowners looking for a low-maintenance flooring option that can add warmth and style to their home" 
    },
    {tab: "linoleum", images: importAll(require.context(`../public/images/linoleum`, false, /\.(png|jpe?g|svg)$/)),
      text: "Linoleum and sheet vinyl both belong to a category of flooring called resilient flooring and are sometimes difficult to tell apart. Both come in wide sheets that are typically glued down, are waterproof, and come printed and textured in a wide variety of styles and colors. Linoleum is an older natural product, while vinyl is synthetic. Sheet vinyl largely replaced Linoleum because it is cheaper, easier to install, and can have more vibrant and varied colors. The majority of floors you see today are actually sheet vinyl but are still referred to as Linoleum. Linoleum is making a small resurgence in popularity because it is natural and sustainable, and can still be found in specialty stores. \n\n One of the benefits of resilient flooring is its durability and resistance to moisture and water, making it a popular choice for areas of the home that are prone to spills or high humidity, such as bathrooms and kitchens. Additionally, sheet vinyl flooring is relatively easy to clean and maintain, and can be a cost-effective option for homeowners on a budget. While sheet vinyl flooring is generally a more affordable option than other types of flooring, it can be susceptible to damage from heavy furniture or sharp objects, and may need to be replaced more frequently than other types of flooring. Additionally, some types of sheet vinyl flooring may release volatile organic compounds (VOCs) into the air, particularly when first installed. Overall, sheet vinyl flooring is a durable, low-maintenance, and affordable option for homeowners looking for a practical and stylish flooring option for their home."
    },
  ];	


	useEffect(() => {
      setSubTab(props.subTab);
	}, [props.subTab]);

	return (
    <Tabs style={{color: 'black', textAlign: 'center'}} variant='soft-rounded' isFitted colorScheme={'cyan'} index={subTab} onChange={(num) =>{ setSubTab(num); }}>
      <Hide below='md'>
        <TabList  mb='1em'>
          <Tab >Laminate</Tab>
          <Tab>Luxury Vinyl Plank</Tab>
          <Tab>Hardwood</Tab>
          <Tab>Tile</Tab>
          <Tab>Carpet</Tab>
          <Tab>Linoleum / Sheet Vinyl</Tab>
        </TabList>        
      </Hide>

    <TabPanels>
      {tabIds.map((id, index) => (
        <TabPanel key={index}>
          <SimpleGrid minChildWidth="300px" spacing='40px' >
            <div>
              <Title>{id.title? id.title : id.tab}</Title>
              <Carousel images={id.images} folder={id.tab} />           
            </div>
            
            <Details>
              {id.text ? id.text : 'nothing here...'}
            </Details>
          </SimpleGrid>
          
        </TabPanel>
        ))}
    </TabPanels>
  </Tabs>  
	);
};

export default Flooring;
