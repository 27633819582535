import React from 'react';
import styled from 'styled-components';
import {  ChakraProvider,  Box,  Text, VStack,  Code,  Grid,  theme, GridItem, Tabs, TabList, Tab, TabPanels, TabPanel, Flex, Hide, SimpleGrid,} from '@chakra-ui/react';
import Carousel from './common/Carousel';
import { useEffect } from 'react';

const Title = styled.h2`
	color: #000;
	font-size: 28px;
	font-weight: 700;
	margin: 4px, 0;  
  text-transform: capitalize;
`;

const Details = styled.p`
  color: #7a7a7a;
  font-size: 1em;
  text-align: left;
  white-space: pre-line;
  display: flex;
  align-items: center;
`;

const Tile = (props) => {
  const [subTab, setSubTab] = React.useState(0);

  const importAll = require =>
  require.keys().reduce((acc, next) => {
    acc[next.replace("./", "")] = require(next);
    return acc;
  }, {});

  const tabIds = [
    {tab: "showers", images: importAll(require.context(`../public/images/showers`, false, /\.(png|jpe?g|svg)$/)),
      text: "Transform your bathroom into a luxurious retreat with a custom tile shower. With endless design possibilities, custom showers allow you to create a unique and personalized space that reflects your style and taste. From intricate mosaic patterns to sleek, modern designs, our team of experts can help you create a one-of-a-kind shower that is both functional and beautiful. \n\nWhether you are looking at natural stone, large format tile, or glass our team will walk you through all the entire installation process to ensure that your custom tile shower is stunning, and long-lasting. Contact us today to schedule a consultation and start designing your dream shower."
    },
    {tab: "tub", images: importAll(require.context(`../public/images/tub`, false, /\.(png|jpe?g|svg)$/)),
      text: "Elevate the look of your bathroom with a custom tile tub surround. A tile tub surround not only adds style and sophistication to your space, but also provides protection from water damage and everyday wear and tear. Our team of experienced professionals can help you choose the perfect tile design to complement your existing bathroom decor and enhance the overall look and feel of the space. \n\n Whether you prefer a classic subway tile or a more intricate mosaic pattern, we can bring your vision to life. Contact us today to schedule a consultation and start planning your custom tile tub surround installation."
    },
    {tab: "back", images: importAll(require.context(`../public/images/back`, false, /\.(png|jpe?g|svg)$/)),
      text: "Upgrade your kitchen or bathroom with a custom tile backsplash. A tile backsplash not only protects your walls from spills and splatters, but also adds a stylish and decorative touch to the space. Whatever your material and vision, we can bring it to life. Contact us today to schedule a consultation and start planning your custom tile backsplash installation."
    },
  ];	

  console.log(tabIds)

	useEffect(() => {
      setSubTab(props.subTab);
	}, [props.subTab]);

	return (
    <Tabs style={{color: 'black', textAlign: 'center' }} isFitted variant='soft-rounded' colorScheme={'cyan'} index={subTab} onChange={(num) =>{ setSubTab(num); }}>
    <Hide below='md'>
      <TabList  mb='1em'>
        <Tab>Showers</Tab>
        <Tab>Tub Surrounds</Tab>
        <Tab>Back Splashes</Tab>
      </TabList>      
    </Hide>

    <TabPanels>
      {tabIds.map((id, index) => (
        <TabPanel key={index}>
          <SimpleGrid minChildWidth="300px" spacing='40px' >
            <div>
              <Title>{id.tab}</Title>
              <Carousel images={id.images} folder={id.tab} />              
            </div>        

            <Details>
              {id.text ? id.text : 'nothing here...'}
            </Details>
          </SimpleGrid>        
        </TabPanel>
      ))}
    </TabPanels>
  </Tabs>  
	);
};

export default Tile;
