import React from 'react';
import styled from 'styled-components';
// import { useHistory } from "react-router-dom";
import { Tabs, TabList, TabPanels, Tab, TabPanel, Select, Menu, MenuButton, MenuList, MenuItem, Button, useDisclosure, Text, Hide } from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons';
import logo from './images/Logo Large.png';

const NavbarContainer = styled.div`
	display: flex;
	align-items: center;
	background-color: #393c3e;
	justify-content: center;
	color: #ffffff;
`;


const NavBar = (props) => {
	const { setShow, setSubTab, width } = props;
	const { isOpen, onOpen, onClose } = useDisclosure()

	return ( 
		<NavbarContainer style={{ width: width }} >
			<Tabs align='center' >

				<TabList>
					<Tab  onClick={() => props.setShow('home')}><img src={logo} style={{ height: '60px' }} alt='Page not found'/></Tab>
					<Hide below='md'>
						<Tab onClick={() => props.setShow('home')}>Home</Tab>
					</Hide>					
					<Tab onClick={() => props.setShow('contact')}>
						Contact Us						
					</Tab>
				</TabList>
			</Tabs>			

			<Menu  >
				<MenuButton variant='ghost' >
					Flooring <ChevronDownIcon />
				</MenuButton>
				<MenuList >
					<MenuItem onClick={() => {props.setShow('flooring'); setSubTab(0); onClose()}}>Laminate</MenuItem>
					<MenuItem onClick={() => {props.setShow('flooring'); setSubTab(1); onClose()}}>Luxury Vinyl Plank</MenuItem>
					<MenuItem onClick={() => {props.setShow('flooring'); setSubTab(2); onClose()}}>Hardwood</MenuItem>
					<MenuItem onClick={() => {props.setShow('flooring'); setSubTab(3); onClose()}}>Tile</MenuItem>
					<MenuItem onClick={() => {props.setShow('flooring'); setSubTab(4); onClose()}}>Carpet</MenuItem>
					<MenuItem onClick={() => {props.setShow('flooring'); setSubTab(5); onClose()}}>Linoleum / Sheet Vinyl</MenuItem>
				</MenuList>
			</Menu>
			
			<Menu >
				<MenuButton variant='ghost'>
					Tile <ChevronDownIcon />
				</MenuButton>
				<MenuList>
					<MenuItem onClick={() => {props.setShow('tile'); setSubTab(0); onClose()}}>Showers</MenuItem>
					<MenuItem onClick={() => {props.setShow('tile'); setSubTab(1); onClose()}}>Tub Surrounds</MenuItem>
					<MenuItem onClick={() => {props.setShow('tile'); setSubTab(2); onClose()}}>Back Splashes</MenuItem>
					{/* <MenuItem onClick={() => {props.setShow('tile'); setSubTab(3); onClose()}}>Floors</MenuItem> */}
				</MenuList>
			</Menu>
		</NavbarContainer>

	);
}
export default NavBar;