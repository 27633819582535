import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {  ChakraProvider,  Grid,  GridItem, Button, Link, Center, Wrap, WrapItem, Hide} from '@chakra-ui/react';
import { FaGoogle, FaFacebook, FaYelp, FaTable } from 'react-icons/fa';
import { StarIcon } from '@chakra-ui/icons'
import ReactCompareImage from 'react-compare-image';


import { Helmet } from "react-helmet";
// import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import NavBar from './NavBar';
import BackgroundScroller from './common/BackgroundScroller';
import ImageTextPair from './common/ImageTextPair';

import backgroundImg from '../src/images/frontpage1.jpg';
import backgroundImg1 from '../src/images/frontpage2.jpg';
import backgroundImg2 from '../src/images/frontpage3.jpg';
import Flooring from './Flooring';
import Tile from './Tile';

import theme from './theming/theme';
import ContactUs from './ContactUs';
import ImageTextPairReversed from './common/ImageTexrPairReversed';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import Review from './common/Review';

function App() {
  const [show, setShow] = useState('home');
  const [subTab, setSubTab] = useState(0);

  const ref = useRef(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setWidth(ref.current.offsetWidth);
    setHeight(ref.current.offsetHeight);
  }, []);

  useEffect(() => {
    function handleWindowResize() {
      setWidth(ref.current.clientWidth);
      setHeight(ref.current.clientHeight);
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const before = 'https://cdn.discordapp.com/attachments/857862435096100884/1120826283807490158/before.png'
  const after = 'https://cdn.discordapp.com/attachments/857862435096100884/1120826284080103554/after.png'

  return (
    <div className="App" ref={ref} width={width}  >
      <ChakraProvider theme={theme}>
        <Helmet>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image" content="https://designcode.io/cloud/v2/twitter.jpg" />
          <meta
            name="twitter:title"
            content="Walnut Creek Flooring And Tile"
          />
          <meta
            name="twitter:description"
            content="Installing Laminate, Vinyl, Carpet, and Tile flooring in the Bay Area"
          />
          
          <meta property="og:type" content="website" />
          <meta property="og:url" content="www.walnutcreekflooringandtile.com" />
          <meta
            property="og:title"
            content="Walnut Creek Flooring And Tile"
          />
          <meta
            property="og:description"
            content="Installing Laminate, Vinyl, Carpet, and Tile flooring in the Bay Area"
          />
          <meta
            property="og:image"
            content="https://designcode.io/cloud/v2/twitter.jpg"
          />
        </Helmet>

      <Grid
        templateAreas={`"header header"
                        "main main"
                        "footer footer"`}
        gridTemplateRows={'80px 1fr 50px'}
        
      >
        <GridItem area={'header'}>
          <NavBar setShow={setShow} setSubTab={setSubTab}
              width={width} />
        </GridItem>

        <GridItem width={'100%'} bg='#fefefc' style={{ height: 'calc(100vh - 130px)', overflow: 'auto',  }}  area={'main'}>
          
        {/* <h2 style={{ color: 'red' }} >Width: {width}</h2>
        <h2 style={{ color: 'red' }}>Height: {height}</h2> */}

          {show === 'home' && <div>
            <ImageTextPair
              title={'Let us take care of your flooring needs!'}
              description={'Looking to transform your home or business with beautiful and durable flooring? Look no further than Walnut Creek Flooring and Tile! Our team of experts has years of experience in the flooring industry, and we\'re dedicated to helping you find the perfect flooring solution for your space. Whether you\'re looking for hardwood, tile, carpet, or vinyl, we have a wide selection of top-quality products to choose from. And with our competitive pricing and exceptional customer service, you can trust us to deliver an exceptional flooring experience from start to finish. Contact us today to schedule your consultation and discover the endless possibilities for your floors!'}
              imgUrl={backgroundImg}
              reversed
              width={width}
            />   

            <Hide below='md'>
              <BackgroundScroller  text={<Center style={{ width: "40vw",  }} >
                <ReactCompareImage 
                  aspectRatio="wider"
                  leftImage={before} 
                  rightImage={after}
                  rightImageLabel={'After'}
                  leftImageLabel={'Before'}
                />
              </Center>} backgroundImg={backgroundImg2} />
            </Hide>		
            
            <ImageTextPairReversed
              title='Have confidence in your tile projects'
              description="At Walnut Creek Flooring and Tile, we specialize in creating stunning and functional tile installations for homes and businesses. Our team of skilled professionals install custom tile showers, tub surrounds, backsplashes, and more. We work closely with our clients to understand their vision and bring their ideas to life, paying close attention to every detail of the installation process. With a focus on exceptional craftsmanship and customer satisfaction, we are committed to delivering results that exceed your expectations. Whether you're looking to update your kitchen or bathroom, or want to add a unique touch to your commercial space, we can help you create a custom tile installation that will elevate the look and feel of the room. Contact us today to schedule a consultation and start planning your next tile project."
              imgUrl={backgroundImg1}
              width={width}
            />  
            
            <Hide below='md'>
              <BackgroundScroller 
              text={
                <>
                  <Review author="Peter M." text={"The boys at Walnut Creek Flooring and tile did a great job installing 450 sq ft of tile in our kitchen and back room. They explained everything  and were very detail oriented. They did such a great job I had them install laminate in the rest of the house, and carpet in my bedroom."} />
                  <Review author="Linda E." text={"The 2 men that came over to remove my carpet and install Luxury Vinyl Plank Flooring did an excellent job. They were on time.  They were both very kind and answered all my questions.  They helped with moving furniture.  The newly installed flooring looks fabulous.  They were efficient and clean.  I would definitely use them again when I have more flooring done."} reversed/>
                </>
              } 
              backgroundImg={backgroundImg} />    
            </Hide>
                
          </div>}
          

          {show === 'contact' && <div>
            <ContactUs />
          </div>}

          {show === 'flooring' && <div>
            <Flooring subTab={subTab} />
          </div>}

          {show === 'tile' && <div>
            <Tile subTab={subTab} />
          </div>}
        </GridItem>

        <GridItem bg='rgb(204, 204, 204)' area={'footer'} alignContent={'center'} alignItems={'center'} display={'flex'} justifyContent={'center'} >   
          <Center>
          <Wrap spacing={4}>
            <WrapItem>
              <Button onClick={() => setShow('contact')} size={'md'} leftIcon={<PhoneIcon />} colorScheme="orange" variant='solid' >Contact Us!</Button>
            </WrapItem>

            {/* <WrapItem>
              <Button colorScheme='red' leftIcon={<FaGoogle />}/>
            </WrapItem> */}

            <WrapItem>
              <Button colorScheme='red' leftIcon={<FaYelp />} onClick={() => window.open('https://www.yelp.com/biz/walnut-creek-flooring-and-tile-concord', '_blank', 'noreferrer')} >
                Yelp
              </Button>
            </WrapItem>

          </Wrap>
          
            

          </Center>       
        </GridItem>
      </Grid>
      </ChakraProvider>

  </div>
  );
}

export default App;
